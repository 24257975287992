import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import Main from '../../../components/Main/Main';
import PageHeader from '../../../components/Pages/PageHeader';
import Table from '../../../components/Table/Table';
import ledgerApi from '../../../services/ledgerApi';
import MerchantStatuses from '../../../components/Merchant/MerchantStatuses';
import gatewayApi from '../../../services/gatewayApi';
import { groupBy } from '../../../helpers/utils';
import ToggleInput from '../../../components/Form/Inputs/ToggleInput';
import { useTranslation } from 'react-i18next';
import ProcessorModal from '../../../components/Modal/ProcessorModal';
import { useFlags } from 'flagsmith/react';
import authenticatorApi from '../../../services/authenticatorApi';
import { processorByCode } from '../../../data/ProcessorCredentials';
import Badge from '../../../components/Badge/Badge';


export default function ListMerchants() {
  const [isLoading, setIsLoading] = useState(true);
  const flags = useFlags(['change_processor_merchants']);
  const [reload, setReload] = useState(true);
  const location = useLocation();
  const [userEmail, setUserEmail] = useState('');
  const [isUserEmailLoaded, setIsUserEmailLoaded] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState({
    merchants: [],
    meta: {}
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({ limit: 1000, skip: 0 });  

  useEffect(() => {
    authenticatorApi
      .get('/app/merchant/users/me')
      .then((response) => {
        setUserEmail(response.data.user.email);
        setIsUserEmailLoaded(true);
      })
      .catch((error) => {
        console.log('Error fetching user info:', error);
        setIsUserEmailLoaded(true);
      });
  }, []);
  
  const processorMerchants = flags.change_processor_merchants.value || [];
  const isChangeProcessorAvailable = isUserEmailLoaded && processorMerchants.includes(userEmail);

  const fetchMerchantsWithProcessors = async (searchParams) => {
    try {
      const { data: merchants } = await ledgerApi.get('/v1/admin/merchants', { params: searchParams });

      const adminToken = localStorage.getItem('@App:token');
      const processorRequests = await merchants.map((merchant) =>
        gatewayApi.get(`/app/admin/processors?merchant_id=${merchant.id}`, {
          headers: { Authorization: `Bearer ${adminToken}` },
        })
      );

      const processorsResponses = await Promise.all(processorRequests);

      const merchantsWithProcessors = merchants.map((merchant, index) => {
        const processors = processorsResponses[index]?.data?.processors ?? [];
        const bestProcessor = processors.reduce(
          (prev, curr) => (prev.precedence < curr.precedence ? prev : curr),
          processors[0] || {}
        );
        const processor = processorByCode(bestProcessor.processor_type); 
        
        return {
          ...merchant,
          processor_name: processor?.name ?? 'N/A', 
          processor_color: processor?.color ?? 'red', 
        };
      });

      return merchantsWithProcessors;
    } catch (error) {
      console.error('Erro ao buscar merchants e processadores:', error);
      return [];
    }
  };

  useEffect(() => {
    if (!reload) {
      return;
    }

    ledgerApi
      .get('/v1/admin/merchants', { params: searchParams })
      .then((response) => {
        setData({ merchants: response.data });
        setIsLoading(false);
      })
      .catch((e) => console.log('e', e));

    setReload(false);
  }, [reload]);

  useEffect(() => {
    setReload(location.state?.reload);
  }, [location.state?.reload]);

  useEffect(() => {
    setIsLoading(true);
    fetchMerchantsWithProcessors(searchParams).then((merchants) => {
      setData({ merchants });
      console.log(data.merchants.length)
      setIsLoading(false);
    });
  }, [searchParams]);

  const handlePageChange = (page) => {
    const newSkip = (page - 1) * searchParams.limit;
    setSearchParams({ ...searchParams, skip: newSkip });
  };

  const columns = [
    {
      title: 'Merchant',
      render: (merchant) => (
        <Link to={`/admin/merchant/${merchant.id}`}>
          <span className="mb-1 block font-semibold">{merchant.name}</span>
          <span className="text-xs text-gray-500">{merchant.id}</span>
        </Link>
      ),
    },
    {
      title: 'Processador Ativo',
      render: (merchant) => {
        return (
          <div>
            <Badge variant={merchant?.processor_color}>{merchant?.processor_name}</Badge>
          </div>
        );
      },
    },
    {
      title: 'Status',
      node: 'status',
      render: (merchant) => <MerchantStatuses merchant={merchant} />,
    },
    {
      render: (merchant) => (
        <Button as={<Link to={`/admin/merchant/${merchant.id}`} />} size="xs" variant="secondary">
          {t('AdmimViewButton')}
        </Button>
      ),
    },
  ];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const toggleIsRegulated = (isChecked) => {
    const p = { limit: 600, q: '' };
    if (isChecked) {
      p.q = 'is_regulated';
    }
    setSearchParams(p);
  };

  return (
    <div>
      <Outlet />

      <PageHeader
        title={t('AdmimMerchant')}
        subtitle={t('AdmimMerchantSubtitles')}
        button={
          <>
            <div className="my-auto">
              <ToggleInput optionLeft="Não Regulado" optionRight="Regulado" onChange={toggleIsRegulated} />
            </div>
            <Button as={<Link />} to="/admin/merchants/new">
              {t('AdmimMerchantButton')}
            </Button>
            {isChangeProcessorAvailable && (
              <Button variant="white" onClick={openModal}>
                {t('AdmimMerchantProcessorButton')}
              </Button>
            )}
          </>
        }
      />

      <Main className="pt-4">
        <Table isLoading={isLoading} columns={columns} rows={data.merchants} />
      </Main>

      <ProcessorModal
        title={t('ProcessorModalTitle')}
        subtitle={t('ProcessorModalSubtitle')}
        bodyText={t('ProcessorModalBodyText')}
        open={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
}
