import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './api'

const financeApi = axios.create({
  baseURL: process.env.REACT_APP_FINANCE_URL
})

financeApi.interceptors.response.use(...responseInterceptors)
financeApi.interceptors.request.use(...requestInterceptors)

export const fetchBalance = async (merchantId, provider) => {
  const adminToken = localStorage.getItem('@App:token')
  const response = await financeApi.get(`api/admin/account-config/transactional/balance/${merchantId}/${provider}`, {
    headers: {
      Authorization: adminToken.includes('Bearer ') ? adminToken : `Bearer ${adminToken}`
    }
  })

  return response
}
