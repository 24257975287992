import React, { useMemo } from 'react'
import { CurrencyDollar, Receipt, Wallet } from 'react-bootstrap-icons'
import StatsPill from '../../components/StatsPill/StatsPill'
import { FormattedCurrency } from '../../components/TransactionHelpers'
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  GlobeEuropeAfricaIcon,
  ScaleIcon,
  ArrowDownRightIcon,
  ArrowUpRightIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import formatNumber from '../../utils/formatNumber'

export default function RecentActivityCards({ data, pixData, pixDataIsEnabled, isLoading, pixDataIsLoading }) {
  const { t } = useTranslation()
  const aggregatedData = useMemo(() => {
    let cash_in = 0
    let cash_out = 0
    let settlement = 0
    let daily_balance = 0
    let total_fees = 0
    let cash_in_count = 0
    let cash_out_count = 0
    let avg_ticket_in = 0
    let avg_ticket_out = 0

    ;(data || []).forEach((balance) => {
      cash_in += parseFloat(balance.cash_in.total_amount)
      cash_out += parseFloat(balance.cash_out.total_amount)
      settlement += parseFloat(balance.cash_out.settlement.total_amount)
      daily_balance += parseFloat(balance.total)
      total_fees += parseFloat(balance.total_fee)
      cash_in_count += parseInt(balance.cash_in.player.counter)
      cash_out_count += parseInt(balance.cash_out.player.counter)
    })

    avg_ticket_in = cash_in / cash_in_count
    avg_ticket_out = cash_out / cash_out_count

    return {
      cash_in,
      cash_out,
      settlement,
      daily_balance,
      total_fees,
      avg_ticket_in,
      avg_ticket_out,
      cash_in_count,
      cash_out_count
    }
  }, [data])

  const cards = [
    {
      name: t('DashboardCashIn'),
      href: '#',
      icon: ArrowDownTrayIcon,
      amount: aggregatedData.cash_in,
      isLoading: isLoading
    },
    {
      name: t('DashboardCashOut'),
      href: '#',
      icon: ArrowUpTrayIcon,
      amount: aggregatedData.cash_out,
      isLoading: isLoading
    },
    {
      name: t('DashboardSettlement'),
      href: '#',
      icon: GlobeEuropeAfricaIcon,
      amount: aggregatedData.settlement,
      isLoading: isLoading
    },
    {
      name: t('DashboardAvgTicketIn'),
      href: '#',
      icon: ArrowTrendingUpIcon,
      amount: aggregatedData.avg_ticket_in,
      isLoading: isLoading
    },
    {
      name: t('DashboardAvgTicketOut'),
      href: '#',
      icon: ArrowTrendingDownIcon,
      amount: aggregatedData.avg_ticket_out,
      isLoading: isLoading
    },
    {
      name: t('DashboardFees'),
      href: '#',
      icon: CurrencyDollar,
      amount: aggregatedData.total_fees,
      isLoading: isLoading
    },
    {
      name: t('DashboardCashInRange'),
      href: '#',
      icon: ArrowUpRightIcon,
      value: formatNumber(aggregatedData.cash_in_count),
      isLoading: isLoading
    },
    {
      name: t('DashboardCashOutRange'),
      href: '#',
      icon: ArrowDownRightIcon,
      value: formatNumber(aggregatedData.cash_out_count),
      isLoading: isLoading
    },
    {
      name: t('DashboardPeriodBalance'),
      href: '#',
      icon: ScaleIcon,
      amount: aggregatedData.daily_balance,
      isLoading: isLoading,
      cta: 'Request Withdraw'
    },
    ...(pixDataIsEnabled
      ? [
          {
            name: t('DashboardGeneratedQrCodes'),
            href: '#',
            icon: ArrowPathIcon,
            value: formatNumber(pixData?.dashboard?.pix.started),
            isLoading: pixDataIsLoading,
            cta: 'Request Withdraw'
          },
          {
            name: t('DashboardPaidQrCodes'),
            href: '#',
            icon: CurrencyDollar,
            value: formatNumber(pixData?.dashboard?.pix.paid),
            isLoading: pixDataIsLoading,
            cta: 'Request Withdraw'
          },
          {
            name: t('DashboardConversionRate'),
            href: '#',
            icon: ScaleIcon,
            value: `${Number(pixData?.dashboard?.pix.rate || 0).toFixed(2)}%`,
            isLoading: pixDataIsLoading,
            cta: 'Request Withdraw'
          }
        ]
      : [])
  ]

  return (
    <div>
      <div className="mb-5 mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {/* Card */}
        {cards.map((card, index) => (
          <React.Fragment key={index}>
            {card && (
              <StatsPill
                title={card.name}
                value={
                  <>
                    {card.isLoading && t('loadingText')}
                    {!card.isLoading && card.hasOwnProperty('amount') && (
                      <FormattedCurrency currency="BRL" amount={card.amount} />
                    )}
                    {!card.isLoading && card.hasOwnProperty('value') && card.value}
                  </>
                }
                icon={<card.icon />}
                otherAction={
                  <>
                    <a href="#" className="font-medium text-chill-700 hover:text-chill-900">
                      View All
                    </a>
                  </>
                }
              />
            )}
            {!card && <div />}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
