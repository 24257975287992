import { stripNonNumbers } from './stripNonNumbers'

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return ''

  const unmaskedValue = stripNonNumbers(phoneNumber)
  const formattedValue = unmaskedValue.replace(/^(\d{2})(\d+)/, '+$1$2')

  return formattedValue
}

export function formatPhoneNumberWithMask(phoneNumber) {
  const cleaned = stripNonNumbers(phoneNumber)

  if (cleaned.length === 0) {
    return ''
  } else if (cleaned.length <= 2) {
    return `(${cleaned}`
  } else if (cleaned.length <= 6) {
    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`
  } else if (cleaned.length <= 10) {
    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`
  }

  return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7, 11)}`
}
